import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { MEMBERSHIP_API } from 'modules/school/api/membership-api'
import { CourseLectureId } from '../types/course-lecture-interface'

export const useCompleteCourseLecture = () => {
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation({
    method: RequestMethodsEnum.post,
  })
  return {
    completeCourseLecture: async (courseLectureId: CourseLectureId) => {
      await fetcher(`${MEMBERSHIP_API}/lecture/${courseLectureId}/complete`)
    },
    isFetching: isFetching,
  }
}
