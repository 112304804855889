import useSWR from 'swr'
import { BareFetcher, PublicConfiguration } from 'swr/_internal'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { MEMBERSHIP_API } from 'modules/school/api/membership-api'
import { CourseLectureDataInterface, CourseLectureId } from '../types/course-lecture-interface'

export interface UseCourseProps {
  courseLectureId: CourseLectureId | undefined
  shouldFetch?: boolean
  onError?: (
    err: Error,
    key?: string,
    config?: Readonly<
      PublicConfiguration<
        CourseLectureDataInterface,
        Error,
        BareFetcher<CourseLectureDataInterface>
      >
    >,
  ) => void
}

export function useCourseLecture({ courseLectureId, shouldFetch = true, onError }: UseCourseProps) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseLectureDataInterface
  >({
    method: RequestMethodsEnum.get,
    accessDeniedResponseHandler: (error) => onError && onError(error),
  })

  const {
    data: courseLecture,
    mutate,
    isValidating,
  } = useSWR<CourseLectureDataInterface>(
    () =>
      courseLectureId !== undefined &&
      shouldFetch &&
      `${MEMBERSHIP_API}/lecture/${courseLectureId}`,
    fetcher,
    { onError, shouldRetryOnError: false },
  )

  return { courseLecture, mutate, isValidating }
}
