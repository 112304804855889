import React from 'react'
import { useTranslation } from 'next-i18next'
import useStepRouter from '../hooks/use-step-router'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'
import { SecondaryButtonWithCustomTheme } from './secondary-button-with-custom-theme'

export const CourseStepButton = () => {
  const { t } = useTranslation()

  const { onNextStep, isEnd } = useStepRouter()

  return (
    <SecondaryButtonWithCustomTheme
      className={`font-bold ${addPublicCustomClassNamePrefix(
        isEnd ? 'course__button--mark-lecture-as-completed' : 'course__button--next-lecture',
      )}`}
      onClick={onNextStep}
      disabled={isEnd}
    >
      {isEnd ? t('course.course_completed') : t('course.next_step_button_label')}
    </SecondaryButtonWithCustomTheme>
  )
}
