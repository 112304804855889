import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { HTMLString } from 'shared/types/html-string'
import { MEMBERSHIP_API } from 'modules/school/api/membership-api'
import { CourseLecturePreviewHash } from '../types/course-lecture-interface'

export interface UseCourseProps {
  hash: CourseLecturePreviewHash | undefined
  shouldRender?: boolean
}

export function useCourseLecturePreview({ hash, shouldRender = true }: UseCourseProps) {
  const { t } = useTranslation()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, HTMLString>({
    method: RequestMethodsEnum.get,
  })

  const { data: courseLecturePreview } = useSWR<HTMLString>(
    () => hash !== undefined && shouldRender && `${MEMBERSHIP_API}/lecture/preview/${hash}`,
    fetcher,
    { onError: () => toast.error(t('global.error')) },
  )

  return { courseLecturePreview }
}
