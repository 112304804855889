import { useEffect, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { AxiosError } from 'axios'
import { useTimer } from 'use-timer'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { LECTURE_LOGGING_API, SESSION_LOGGING_API } from '../api/session-logging-api'
import {
  CourseLectureId,
  LectureCreateSessionInterface,
  LectureSessionId,
} from '../types/course-lecture-interface'
import { CourseStepEnum } from '../types/course-step-type'
import { useCourseData } from './use-course-data'
import useStepRouter from './use-step-router'

const LECTURE_START_LOG_OFFSET = 5
const LECTURE_LOG_UPDATE_INTERVAL = 30

export function useCourseTracking(shouldSendLog: boolean) {
  const { fetcher: createFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    LectureCreateSessionInterface
  >({ method: RequestMethodsEnum.post, accessDeniedResponseHandler: () => null })
  const { fetcher: updateFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.post>({
    method: RequestMethodsEnum.post,
  })
  const [lectureSessionId, setLectureSessionId] = useState<LectureSessionId>()
  const [lectureId, setLectureId] = useState<CourseLectureId>()
  const [isFetching, setIsFetching] = useState(false)
  const [isRequestedCreation, setIsRequestedCreation] = useState(false)
  const { step } = useStepRouter()
  const { courseData } = useCourseData()
  const isLoggingEnabled = shouldSendLog && courseData?.lectureSessionLogging
  const isVisible = usePageVisibility()
  const staleTimer = useTimer()
  const updateTimer = useTimer({
    initialTime: LECTURE_LOG_UPDATE_INTERVAL,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: handleTimeOver,
  })

  async function handleTimeOver() {
    if (isFetching) return

    setIsFetching(true)

    try {
      if (!lectureId) return

      if (!lectureSessionId) {
        const { lectureSessionId: sessionId } = await startTrackingSession(lectureId)
        setLectureSessionId(sessionId)
      } else {
        await updateTrackingSession(lectureSessionId!)
      }
    } catch (e) {
      const error = e as AxiosError

      if ([403, 406].includes(error?.response?.status ?? -1)) {
        updateTimer.reset()
      }
    } finally {
      updateTimer.reset()
      updateTimer.start()
      setIsFetching(false)
    }
  }

  function startTrackingSession(lectureId: CourseLectureId) {
    return createFetcher(`${LECTURE_LOGGING_API}/${lectureId}/session/create`)
  }

  function updateTrackingSession(lectureSessionId: LectureSessionId) {
    return updateFetcher(`${SESSION_LOGGING_API}/${lectureSessionId}`)
  }

  useEffect(() => {
    if (step?.type === CourseStepEnum.lecture && step?.id && step?.id !== lectureId) {
      setLectureId(step.id)
      setLectureSessionId(undefined)
      setIsRequestedCreation(false)
    }
  }, [step?.id, step?.type, lectureId])

  useEffect(() => {
    if (!isLoggingEnabled) {
      return
    }

    if (lectureId && !isRequestedCreation) {
      setTimeout(handleTimeOver, LECTURE_START_LOG_OFFSET * 1000)
      setIsRequestedCreation(true)
    }
  }, [lectureId, isRequestedCreation])

  useEffect(() => {
    if (!isLoggingEnabled) {
      return
    }

    if (!isVisible) {
      staleTimer.start()
    }

    if (isVisible && staleTimer.isRunning) {
      if (staleTimer.time > LECTURE_LOG_UPDATE_INTERVAL) {
        updateTimer.reset()
        setLectureSessionId(undefined)
      }

      staleTimer.reset()
    }
  }, [isVisible, staleTimer.isRunning])

  useEffect(() => {
    if (!isLoggingEnabled) {
      updateTimer.reset()
      setIsRequestedCreation(false)
    }

    if (isLoggingEnabled && !updateTimer.isRunning) {
      updateTimer.start()
    }
  }, [isLoggingEnabled])
}
