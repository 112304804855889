import { RefObject, useLayoutEffect } from 'react'
import { useSetFonts } from './use-set-fonts'

export const useFixFontsForIframe = ({
  iframeRef,
}: {
  iframeRef: RefObject<HTMLIFrameElement>
}) => {
  const { fontStyle, fontFamily } = useSetFonts()

  useLayoutEffect(() => {
    if (fontFamily && fontStyle && iframeRef.current?.contentDocument?.body) {
      const style = iframeRef.current?.contentDocument.createElement('style')
      style.textContent = fontStyle
      iframeRef.current.contentDocument.head.appendChild(style)

      iframeRef.current.contentDocument.body.style.fontFamily = fontFamily
    }
  }, [iframeRef, iframeRef.current?.contentDocument?.body, fontFamily, fontStyle])
}
