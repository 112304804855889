import React from 'react'
import { useTranslation } from 'next-i18next'
import { PrimaryButtonWithCustomTheme } from 'modules/course/components/primary-button-with-custom-theme'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { useCourseMenu } from 'modules/course/hooks/use-course-menu'
import useStepRouter from 'modules/course/hooks/use-step-router'
import { CourseLectureId } from 'modules/course/types/course-lecture-interface'
import { CourseQuizId } from 'modules/course/types/course-quiz-interface'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

interface CourseNeedCompleteModuleScreenProps {
  setOpenPassQuiz: React.Dispatch<
    React.SetStateAction<false | { unavailableStep: CourseLectureId | CourseQuizId }>
  >
}

export const CourseNeedCompleteModuleScreen = ({
  setOpenPassQuiz,
}: React.PropsWithChildren<CourseNeedCompleteModuleScreenProps>) => {
  const { t } = useTranslation()

  const { findStepById, redirectToStep } = useStepRouter()
  const { courseData } = useCourseData()
  const { courseMenu } = useCourseMenu({ courseId: courseData?.id })

  const moduleNeedToPass = courseMenu?.find(module => module.quiz && !module.quiz.completed)

  const lectureOrQuizNeedToPass =
    moduleNeedToPass?.lectures.find(lecture => !lecture.completed) || moduleNeedToPass?.quiz

  const lectureOrQuizNeedToPassStep = findStepById(lectureOrQuizNeedToPass?.id)

  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(`course__info-screen--need-to-complete-module`)}
      text={t('course.quiz.complete_previous_module_message')}
      buttons={
        <PrimaryButtonWithCustomTheme
          className={`font-bold ${addPublicCustomClassNamePrefix(
            'course__button--go-to-uncompleted-module',
          )}`}
          onClick={() => {
            if (lectureOrQuizNeedToPassStep) {
              redirectToStep(lectureOrQuizNeedToPassStep)
              setOpenPassQuiz(false)
            }
          }}
        >
          {t('course.quiz.go_to_uncompleted_module_button')}
        </PrimaryButtonWithCustomTheme>
      }
    />
  )
}
