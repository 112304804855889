import React from 'react'

function ModuleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="123"
      height="106"
      viewBox="0 0 123 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.2058 98.3658H16.1625C11.322 98.3658 7.38389 94.4441 7.38389 89.6282V35.3565H114.82V49.7592C114.82 51.798 116.473 53.4512 118.511 53.4512C120.55 53.4512 122.203 51.798 122.203 49.7592V16.1215C122.203 7.23211 114.971 0 106.082 0H16.1625C7.25262 0 0 7.23211 0 16.1215V89.6322C0 98.5216 7.25262 105.754 16.1625 105.754H45.2058C47.2446 105.754 48.8978 104.101 48.8978 102.062C48.8978 100.023 47.2446 98.3658 45.2058 98.3658ZM16.1625 7.37979H106.082C110.902 7.37979 114.82 11.3015 114.82 16.1174V27.9685H7.38389V16.1215C7.38389 11.3015 11.322 7.37979 16.1625 7.37979Z"
        fill="#142D63"
      />
      <path
        d="M21.3158 21.1959C23.2725 21.1959 24.86 19.6084 24.86 17.6516C24.86 15.6949 23.2725 14.1033 21.3158 14.1033C19.359 14.1033 17.7715 15.6908 17.7715 17.6475C17.7715 19.6043 19.3549 21.1959 21.3158 21.1959Z"
        fill="#142D63"
      />
      <path
        d="M33.8065 21.1959C35.7632 21.1959 37.3507 19.6084 37.3507 17.6516C37.3507 15.6949 35.7632 14.1033 33.8065 14.1033C31.8497 14.1033 30.2622 15.6908 30.2622 17.6475C30.2622 19.6043 31.8456 21.1959 33.8065 21.1959Z"
        fill="#142D63"
      />
      <path
        d="M46.2967 21.196C48.2534 21.196 49.841 19.6084 49.841 17.6517C49.841 15.695 48.2534 14.1074 46.2967 14.1074C44.34 14.1074 42.7524 15.695 42.7524 17.6517C42.7524 19.6084 44.3359 21.196 46.2967 21.196Z"
        fill="#142D63"
      />
      <path
        d="M120.132 65.4135C116.095 61.7707 110.25 59.6827 104.101 59.6827C98.7144 59.6827 93.5457 61.3031 89.6486 64.1213C85.7516 61.299 80.5828 59.6827 75.1967 59.6827C69.0476 59.6827 63.202 61.7707 59.1695 65.4094C57.8404 66.6031 57.0815 68.3588 57.0815 70.2212V96.4053C57.0815 98.7066 58.2507 100.782 60.2115 101.964C62.2257 103.178 64.6911 103.24 66.7914 102.12C69.2404 100.823 72.1488 100.138 75.2008 100.138C79.9429 100.138 84.3363 101.808 86.9617 104.601C87.6591 105.344 88.6354 105.766 89.6527 105.766C90.6701 105.766 91.6464 105.344 92.3437 104.601C94.9691 101.804 99.3625 100.138 104.105 100.138C107.157 100.138 110.065 100.823 112.514 102.12C114.623 103.235 117.08 103.178 119.094 101.964C121.055 100.782 122.224 98.7066 122.224 96.4053V70.2212C122.22 68.3588 121.457 66.6072 120.132 65.4135ZM64.4613 95.0393V70.5945C67.1523 68.3506 71.033 67.0707 75.1967 67.0707C79.4178 67.0707 83.2903 68.4491 85.9567 70.7135V95.1049C82.7242 93.6158 79.024 92.7667 75.1967 92.7667C71.394 92.7585 67.7102 93.5461 64.4613 95.0393ZM114.836 95.0393C108.313 92.0365 99.8425 92.2375 93.3406 95.1459V70.7094C96.007 68.445 99.8794 67.0666 104.101 67.0666C108.26 67.0666 112.141 68.3465 114.836 70.5904V95.0393Z"
        fill="#00A0FF"
      />
    </svg>
  )
}

export default ModuleIcon
