import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import { CourseNeedCompleteModuleScreen } from 'modules/course/components/course-info-screens/course-need-complete-module-screen'
import CourseLayout from 'modules/course/components/course-layout'
import { CourseLecture } from 'modules/course/components/course-lecture'
import useStepRouter from 'modules/course/hooks/use-step-router'
import { getCoursePageLayout } from 'modules/course/layouts/get-course-page-layout'
import { CourseLectureId } from 'modules/course/types/course-lecture-interface'
import { CourseQuizId } from 'modules/course/types/course-quiz-interface'
import { parseQueryId } from 'modules/course/utils/parse-query-id'

const LecturePage = () => {
  const router = useRouter()
  const { lectureId: rawLectureId, previewHash } = router.query
  const lectureId = parseQueryId<CourseLectureId>(rawLectureId)

  const [openPassQuiz, setOpenPassQuiz] = useState<
    false | { unavailableStep: CourseLectureId | CourseQuizId }
  >(false)

  const { step, isPreview } = useStepRouter()

  useEffect(() => {
    if (lectureId && openPassQuiz && lectureId !== openPassQuiz?.unavailableStep) {
      setOpenPassQuiz(false)
    }
  }, [lectureId, openPassQuiz])

  return (
    <CourseLayout>
      {openPassQuiz ? (
        <CourseNeedCompleteModuleScreen setOpenPassQuiz={setOpenPassQuiz} />
      ) : (
        <CourseLecture
          lectureId={lectureId}
          isPreview={isPreview}
          previewHash={previewHash as string}
          setOpenPassQuiz={setOpenPassQuiz}
        />
      )}
    </CourseLayout>
  )
}

LecturePage.getLayout = getCoursePageLayout

export const getServerSideProps = baseServerSideProps

export default LecturePage
