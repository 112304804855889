// NOTE: fix scrolling to anchors
import { RefObject, useLayoutEffect } from 'react'
import { getFullOffsetOfElement } from 'shared/utils/get-full-offset-of-element'
import { isFirefox } from 'shared/utils/is-firefox'

export const useFixAnchorsForIframe = ({
  iframeRef,
}: {
  iframeRef: RefObject<HTMLIFrameElement>
}) => {
  useLayoutEffect(() => {
    const setHash = () => {
      if (
        window.location.hash &&
        iframeRef.current?.contentDocument?.body.querySelector(window.location.hash)
      ) {
        window.scrollTo(
          0,
          getFullOffsetOfElement(
            iframeRef.current.contentDocument.body.querySelector(
              window.location.hash,
            ) as HTMLElement,
          ) + getFullOffsetOfElement(iframeRef.current),
        )
      }
    }
    const setHashWithTimeout = () => {
      setHash()
      // NOTE: (for Firefox) clear hash to have ability to scroll to the same hash, if the same hash is already selected
      isFirefox() &&
        setTimeout(() => {
          // @ts-ignore
          window.location.hash = null
        }, 1000)
    }
    setHash()
    window.addEventListener('hashchange', setHashWithTimeout)
    window.addEventListener('popstate', setHashWithTimeout)
    return () => {
      window.removeEventListener('hashchange', setHashWithTimeout)
      window.removeEventListener('popstate', setHashWithTimeout)
    }
  }, [iframeRef, iframeRef.current?.contentWindow, iframeRef.current?.contentDocument])
}
