import React from 'react'
import { useTranslation } from 'next-i18next'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ModuleIcon from 'shared/icons/module-icon'
import { getLoginUrl } from 'shared/utils/get-login-url'
import { PrimaryButtonWithCustomTheme } from 'modules/course/components/primary-button-with-custom-theme'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { SecondaryButtonWithCustomTheme } from '../secondary-button-with-custom-theme'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

export const CoursePurchaseModuleScreen = ({ moduleLink }: { moduleLink: string }) => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { courseData } = useCourseData()

  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(
        `course__info-screen--login-and-purchase-module-or-course`,
      )}
      icon={<ModuleIcon className="h-[80px] w-[80px]" />}
      text={t('course.purchase_module_or_course_button')}
      buttons={
        <>
          {user && !isAuthorizedUser(user) && (
            <PrimaryButtonWithCustomTheme
              className={`font-bold ${addPublicCustomClassNamePrefix('course__button--login')}`}
              href={user?.dashboardLocale && getLoginUrl(user.dashboardLocale)}
              target="_blank"
            >
              {t('user.profile.menu.login')}
            </PrimaryButtonWithCustomTheme>
          )}
          <PrimaryButtonWithCustomTheme
            className={`font-bold ${addPublicCustomClassNamePrefix('course__button--purchase-module')}`}
            href={moduleLink}
            target="_blank"
          >
            {t('course.purchase_module_button')}
          </PrimaryButtonWithCustomTheme>
          {courseData?.salesPage && (
            <SecondaryButtonWithCustomTheme
              className={`font-bold ${addPublicCustomClassNamePrefix(
                'course__button--purchase-course',
              )}`}
              href={courseData?.salesPage || ''}
              target="_blank"
            >
              {t('course.purchase_course_button')}
            </SecondaryButtonWithCustomTheme>
          )}
        </>
      }
    />
  )
}
