import { useRollbar } from '@rollbar/react'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { CommentsList, CommentsTypeEnum } from '@systemeio/comments'
import { useTranslation } from 'next-i18next'
import { AccessDenied, getAccessDeniedText } from 'shared/errors/access-denied'
import { NotFound } from 'shared/errors/not-found'
import useUser from 'shared/hooks/use-user'
import { useCompleteCourseLecture } from '../hooks/use-complete-course-lecture'
import { useCourseData } from '../hooks/use-course-data'
import { useCourseLecture } from '../hooks/use-course-lecture'
import { useCourseLecturePreview } from '../hooks/use-course-lecture-preview'
import { useCourseMenu } from '../hooks/use-course-menu'
import { useCourseTracking } from '../hooks/use-course-tracking'
import { useFixAnchorsForIframe } from '../hooks/use-fix-anchors-for-iframe'
import { useFixFontsForIframe } from '../hooks/use-fix-fonts-for-iframe'
import useStepRouter from '../hooks/use-step-router'
import {
  CourseLectureAccessDeniedDataInterface,
  CourseLectureId,
} from '../types/course-lecture-interface'
import { CourseQuizId } from '../types/course-quiz-interface'
import { addPublicCustomClassNamePrefix } from '../utils/add-class-prefix'
import { CourseContentSkeleton } from './course-content-skeleton'
import { CoursePurchaseModuleScreen } from './course-info-screens/course-purchase-module-screen'
import { CourseStepButton } from './course-state-button'
import { SecondaryButtonWithCustomTheme } from './secondary-button-with-custom-theme'
import { CourseInfoScreenLayout } from './course-info-screens/course-info-screen-layout'

interface CourseLectureProps {
  setOpenPassQuiz: React.Dispatch<
    React.SetStateAction<false | { unavailableStep: CourseLectureId | CourseQuizId }>
  >
  lectureId: CourseLectureId
  isPreview: boolean
  previewHash?: string
}

export const CourseLecture = ({
  setOpenPassQuiz,
  lectureId,
  isPreview,
  previewHash,
}: CourseLectureProps) => {
  const { t } = useTranslation()

  const rollbar = useRollbar()

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [iframeResized, setIframeResized] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [moduleLink, setModuleLink] = useState<string | null>(null)
  const [shouldSendLog, setShouldSendLog] = useState(true)
  const [accessError, setAccessError] = useState<string | null>(null)
  const { step, onNextStep } = useStepRouter()

  const { user } = useUser()

  const { completeCourseLecture, isFetching } = useCompleteCourseLecture()
  const { courseData, mutate: mutateCourseData } = useCourseData()
  const { courseMenu } = useCourseMenu({ courseId: courseData?.id })
  const { courseLecture, isValidating } = useCourseLecture({
    courseLectureId: courseMenu && lectureId,
    shouldFetch: !isPreview,
    onError: error => {
      if(error instanceof AccessDenied && 'reason' in error.response?.data) {
        setAccessError(getAccessDeniedText(error.response?.data.reason))
      } else if (error instanceof AccessDenied && step) {
        const data = error.response?.data as
          | CourseLectureAccessDeniedDataInterface
          | null
          | undefined
        if (data && 'module' in data) {
          // NOTE: sales page for module
          setModuleLink(data.module as string)
        } else {
          setOpenPassQuiz({ unavailableStep: step.id })
        }
      } else if (error instanceof NotFound) {
      } else {
        toast.error('global.error')
      }
      setShouldSendLog(false)
    },
  })
  const { courseLecturePreview } = useCourseLecturePreview({
    hash: previewHash,
    shouldRender: isPreview,
  })

  const { mutate } = useCourseMenu({ courseId: courseData?.id })

  useCourseTracking(
    !isValidating && !!(courseLecture?.html || courseLecturePreview) && shouldSendLog,
  )

  const onCompleteCourseLecture = async () => {
    if (step?.id) {
      await completeCourseLecture(step?.id)
      await mutate(
        data =>
          data?.map(module => ({
            ...module,
            lectures: module.lectures.map(lecture =>
              lecture.id === step?.id ? { ...lecture, completed: true } : lecture,
            ),
            quiz:
              module.quiz &&
              module.lectures.every(lecture => lecture.completed || lecture.id === step?.id)
                ? { ...module.quiz, available: true }
                : module.quiz,
          })),
        false,
      )
      mutateCourseData()
      setCompleted(true)
    }
  }

  const disableRightClick = () => {
    if (iframeRef.current && iframeRef.current.contentDocument?.body) {
      iframeRef.current.contentDocument.body.addEventListener('contextmenu', function (e) {
        const element = e.target as HTMLElement
        if (element.tagName === 'VIDEO' || element.tagName === 'AUDIO') {
          e.preventDefault()
        }
      })
    }
  }

  useEffect(() => {
    if (courseData && courseData.disableRightClick) {
      disableRightClick()
    }
  }, [courseData, iframeRef.current?.contentDocument?.body])

  useEffect(() => {
    // NOTE: we need set a next step in the useEffect because otherwise the mutation of the courseData will have no impact
    if (completed) {
      onNextStep()
      setCompleted(false)
    }
  }, [completed, onNextStep])

  useEffect(() => {
    setModuleLink(null)
  }, [step?.id])

  const fixLinksHandle = () => {
    // NOTE: set target="_parent" to have ability open the link in the same tab
    const iframeDocument = iframeRef.current?.contentDocument
    if (iframeDocument) {
      const links = Array.from(iframeDocument.querySelectorAll('a'))
      links.forEach(link => {
        if (link.getAttribute('target') !== '_blank') {
          link.setAttribute('target', '_parent')
        }
      })
    }
  }

  useLayoutEffect(() => {
    fixLinksHandle()
  }, [iframeRef.current?.contentDocument?.body, courseLecture, courseLecturePreview])

  // dirty hack until we remove the "iframe" approve
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      fixLinksHandle()
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [iframeRef.current?.contentDocument?.body, courseLecture, courseLecturePreview])

  useLayoutEffect(() => {
    const receiveMessage = (event: any) => {
      if (
        iframeRef.current &&
        iframeRef.current.contentDocument?.body &&
        event?.data?.type === 'lecture_content_height'
      ) {
        iframeRef.current.style.height = `${event.data.height}px`
        iframeRef.current.contentDocument.body.style.overflowY = 'hidden'
        setIframeResized(true)
      }
    }
    window.addEventListener('message', receiveMessage)
    return () => window.addEventListener('message', receiveMessage)
  }, [setIframeResized])

  useLayoutEffect(() => {
    setIframeResized(false)
  }, [courseLecture, courseLecturePreview])

  useFixAnchorsForIframe({ iframeRef })

  useFixFontsForIframe({ iframeRef })

  return moduleLink ? (
    <CoursePurchaseModuleScreen moduleLink={moduleLink} />
  ) : !isValidating && (courseLecture?.html || courseLecturePreview) ? (
    <div
      className={`relative w-full ${addPublicCustomClassNamePrefix('course-lecture__container')}`}
    >
      <div
        className={`relative w-full overflow-y-hidden rounded-md bg-THEME-block-background [&>*::-webkit-scrollbar]:hidden`}
      >
        <iframe
          ref={iframeRef}
          className={`hide-scrollbar h-screen w-full overflow-hidden`}
          style={{
            height: iframeRef.current?.style.height || '',
            ...(iframeResized
              ? {
                  position: 'relative',
                  opacity: 1,
                }
              : {
                  position: 'absolute',
                  opacity: 0,
                  height: 0,
                }),
          }}
          srcDoc={courseLecture?.html || courseLecturePreview}
          seamless
        />
        {iframeResized && user && courseLecture?.showComments && (
          <div
            className={`px-3 py-4 lg:px-4 lg:py-5 ${addPublicCustomClassNamePrefix('course-lecture__comments-container')}`}
          >
            <CommentsList
              commentsType={CommentsTypeEnum.page}
              onError={() => toast.error(t('comments.error.error-loading-comments'))}
              pageId={courseLecture?.pageId}
              locale={user.dashboardLocale}
              rollbar={rollbar}
            />
          </div>
        )}
        {!iframeResized && (
          <CourseContentSkeleton
            className={`z-40 ${addPublicCustomClassNamePrefix('course__content-skeleton--lecture')}`}
          />
        )}
      </div>
      {iframeResized && (
        <div className="mt-5 flex justify-center rounded-md bg-THEME-block-background p-6">
          {step?.completed ? (
            <CourseStepButton />
          ) : (
            <SecondaryButtonWithCustomTheme
              isLoading={isFetching}
              disabled={isPreview}
              className={`font-bold ${addPublicCustomClassNamePrefix('course__button--mark-as-completed')}`}
              onClick={async () => {
                await onCompleteCourseLecture()
              }}
            >
              {t('course.mark_step_as_completed_button_label')}
            </SecondaryButtonWithCustomTheme>
          )}
        </div>
      )}
    </div>
  ) : accessError ? (
    <CourseInfoScreenLayout
      text={t(accessError)}
    />
  ) : (
    <CourseContentSkeleton />
  )
}
